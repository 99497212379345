<template>
  <ion-page class="page" :class="{ 'force-full-height': isShortHeight }">
    <div class="content align-items-center justify-content-center m-auto">
      <SignupForm class="signup-form" />
      <div class="rest">
        <SignupParagraphs />
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import SignupParagraphs from './components/SignupParagraphs.vue';
import SignupForm from './components/SignupForm.vue';

const { isShortHeight } = useWindowSize();

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});
</script>

<style scoped lang="sass">
.page
  padding: 20px
  min-height: unset !important
  height: calc(100% - 75px - var(--safe-ios-margin)/3)
  &.force-full-height
    height: 100%
  *
    font-size: 0.9rem
  @media(max-height: 400px)
    height: 100%
.rest
  width: 100%
  @media(max-height: 400px)
    display: none
.content
  display: flex
  align-items: center
  flex-direction: column
  height: 100%
  max-width: 350px
  min-width: 300px
.logo
  height: 50px
.twitter-button, .apple-button
  margin-top: 10px
.signup-form
  margin: 20px 0 5px 0

@media (max-height: 667px)
  .twitter-button, .apple-button
    margin-top: 0px
  .or-separator
    min-height: 15px
  ::v-deep
    input, button, .c-button
      max-height: 35px !important
    .c-input
      &:not(:first-of-type)
        margin-top: 5px !important
    .pw-input, .signup-button
      margin-top: 5px !important
    p
      margin: 8px
</style>
